
  import { Component, Prop } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'

interface Icon {
  icon: string
  color: string
}

@Component({
  methods: { fixPrice },
})
  export default class ExpandableExpenseDetail extends Expandable {
  @Prop({
    type: Object,
    default: () => {
      return { icon: '', color: '' }
    },
  }) approvedIcon!: Icon

  @Prop({
    type: Object,
    default: () => {
      return { icon: '', color: '' }
    },
  }) missingIcon!: Icon

  get iconTitle (): Icon {
    const { approvedIcon, missingIcon } = this

    return approvedIcon || missingIcon
  }
  }
